import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginActivateGuard implements CanActivate {

  constructor(private userService: UserService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.userService.loginToLindiLib().then(loggedIn => {
        if (loggedIn) {
          return true;
        } else {
          console.log('no luck, returning to login')
          this.router.navigate(['login']);
          return false;
        }
      });
      // if (!this.userService.isLoggedIn) {
      //   this.router.navigate(['login']);
      //   return false;
      // }
      // return true;
  }
  
}
