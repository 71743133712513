import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private toastController: ToastController) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(
      event => {
      },
      err => {
        this.showErrorMessage(err?.error?.message);
      }
    ))
  }

  private async showErrorMessage(message: string) {
    const toast = await this.toastController.create({
      message: 'Server side error happened, please try again later, or contact support if problem persists.\n Error message: ' + message,
      duration: 4000,
      color: "danger"
    });
    toast.present();
  }
}