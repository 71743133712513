import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { LindiLibApiService } from '../api/lindi-lib-api.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const appToken = this.userService.getAppAuthToken();
    if (appToken && request.url.startsWith(LindiLibApiService.BASE_URL)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${appToken}`
        }
      });
    }
    return next.handle(request);
  }
}
