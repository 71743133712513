import { Injectable } from '@angular/core';
import * as orders from './data/orders.json';
import * as usersResponse from './data/admin_user_response.json';
import * as bookadmin from './data/bookadmin.json';
import * as tags from './data/tags.json';
import * as authors from './data/authors.json';
import * as publishers from './data/publishers.json';
import recommendationsResponse from './data/recommendations_response.json';
import searchBookResponse from './data/search_response.json';
import bookDetails from './data/bookdetails_response.json';
import cartResponse from './data/cart_response.json';
import { of, Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { Order, OrderForCreation } from './model/order';
import { Author, AdminBook, SearchAdminBookResponse, Tag } from './model/admin/book';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BookDetails, GetBookResponse, RecommendationsResponse, SearchBookResponse } from './model/browse/book';
import { LoginResponse, RenewTokenResponse } from './model/user/login';
import { NavigationEnd, Router } from '@angular/router';
import { GetCartResponse } from './model/cart/cart';
import { AdminUser } from './model/user/user';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class LindiLibApiService {

  public static BASE_URL: string = "https://lindi-lib-api.ew.r.appspot.com/";
  private REAL_BACKEND: boolean = true;
  private loadingCounter: number = 0;
  public history = [];

  isLoading(): boolean {
    return this.loadingCounter > 0;
  }

  constructor(private httpClient: HttpClient,
        private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
   }

  loadBookDetails(bookId: string): Observable<GetBookResponse> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "books/get";
      return this.httpClient
        .post(endpoint, {"bookId": bookId}, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(bookDetails);
    }
  }

  public allAdminBooks: AdminBook[] = [];
  public allBooks: BookDetails[] = [];

  loadAllAdminBooks(searchTerm: string, offset: number): Observable<SearchAdminBookResponse> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "adminbooks/search"
      return this.httpClient
        .post(endpoint, { "searchTerm": searchTerm, "offset": offset }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          if (offset == 0) {
            this.allAdminBooks = response.books;
          } else {
            this.allAdminBooks = this.allAdminBooks.concat(response.books);
          }
          return response;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(bookadmin as SearchAdminBookResponse);
    }
  }

  getAdminBook(bookId: number): Observable<AdminBook> {
    if (this.REAL_BACKEND) {
      const endpoint = LindiLibApiService.BASE_URL + "adminbooks/get"
      return this.httpClient
        .post(endpoint, { bookId }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(a => a.book));
    } else {
      return of(bookadmin.books as Array<AdminBook>)[0];
    }
  }

  updateAdminBook(newBook: AdminBook) {
    let index = -1;
    for (let i = 0; i<this.allAdminBooks.length; i++) {
      if (this.allAdminBooks[i].id == newBook.id) {
        index = i;
      }
    }
    if (index == -1) {
      this.allAdminBooks.push(newBook);
    } else {
      this.allAdminBooks[index] = newBook;
    }
  }

  loadAllTags(): Observable<Tag[]> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "tags/search"
      return this.httpClient
        .post(endpoint, { "searchTerm": "" }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(a => {
          this.loadingCounter--;
          return a.tags;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(tags.tags as Array<Tag>);
    }    
  }

  loadAllAuthors(): Observable<Author[]> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "authors/search"
      return this.httpClient
        .post(endpoint, { "searchTerm": "" }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(a => { 
          this.loadingCounter--;
          return a.authors;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(authors.authors as Array<Author>);
    }
  }

  loadAllPublishers(): Observable<string[]> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "adminbooks/get-all-publishers"
      return this.httpClient
        .get(endpoint, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(a => { 
          this.loadingCounter--;
          return a.publishers;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(publishers.publishers as Array<string>);
    }
  }

  private extractData(res: any) {
    let body = res;
    return body || {};
  }

  saveAdminBook(book: AdminBook): Observable<AdminBook> {
    if (this.REAL_BACKEND) {
      const endpoint = LindiLibApiService.BASE_URL + "adminbooks/save"
      return this.httpClient
        .post(endpoint, { "book": book }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(a => a.book));
    } else {
      return of({id: 1} as AdminBook);
    }
  }  

  saveAdminBookImages(bookId: number, filesToUpload: File[], seqNums: number[]): Observable<boolean> {
    if (this.REAL_BACKEND) {
      const endpoint = LindiLibApiService.BASE_URL + "images/save";
      const formData: FormData = new FormData();
      formData.append("bookId", bookId.toString());
      for (let i=0; i<filesToUpload.length; i++) {
        formData.append("files[]", filesToUpload[i], filesToUpload[i].name);
        formData.append("seqNums[]", seqNums[i].toString());
      }
    
      return this.httpClient
        .post(endpoint, formData, { headers: {} })
        .pipe(
          map(data => true),
          catchError( error => of(false)));
    } else {
      return of(true);
    }
  }

  getImageUrl(bookId: number, bookImageId: number, isThumbnail: boolean): string {
    return `${LindiLibApiService.BASE_URL}images/get?bookId=${bookId}&bookImageId=${bookImageId}&thumbnail=${isThumbnail}`;
  }

  getRecommendations(): Observable<RecommendationsResponse> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "recommendations/list"
      return this.httpClient
        .get(endpoint, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(recommendationsResponse);
    }
  }

  searchBooks(searchTerm: string, offset: number): Observable<SearchBookResponse> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "books/search"
      return this.httpClient
        .post(endpoint, { "searchTerm": searchTerm, "offset": offset }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          if (offset == 0) {
            this.allBooks = response.books;
          } else {
            this.allBooks = this.allBooks.concat(response.books);
          }
          return response;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
          let response = searchBookResponse as SearchBookResponse;
          if (offset == 0) {
            this.allBooks = response.books;
          } else {
            this.allBooks = this.allBooks.concat(response.books);
          }
        return of(response);
    }
  }

  login(appAuthToken: string): Observable<LoginResponse> {
    if (this.REAL_BACKEND) {
      const endpoint = LindiLibApiService.BASE_URL + "users/login"
      return this.httpClient
        .post(endpoint, { appAuthToken }, httpOptions)
        .pipe(map(this.extractData));
    } else {
      return of({longLivedFacebookAuthToken: 'demotoken-longLivedFacebookAuthToken'} as LoginResponse);
    }
  }

  renewToken(facebookAuthToken: string): Observable<RenewTokenResponse> {
    if (this.REAL_BACKEND) {
      const endpoint = LindiLibApiService.BASE_URL + "users/renew-token"
      return this.httpClient
        .post(endpoint, { facebookAuthToken }, httpOptions)
        .pipe(map(this.extractData));
    } else {
      return of({
        longLivedFacebookAuthToken: 'demotoken-longLivedFacebookAuthToken',
        appAuthToken: 'demotoken-appAuthToken'} as RenewTokenResponse);
    }
  }

  getCart(): Observable<BookDetails[]> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "cart/list"
      return this.httpClient
        .get(endpoint, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response.books;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of((cartResponse as GetCartResponse).books);
    }
  }

  addToCart(bookId): Observable<BookDetails> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "cart/add"
      return this.httpClient
        .post(endpoint, { "bookId": bookId }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response.book;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(bookDetails.book);
    }
  }

  removeFromCart(bookId): Observable<void> {
    if (this.REAL_BACKEND) {
      const endpoint = LindiLibApiService.BASE_URL + "cart/remove"
      return this.httpClient
        .post(endpoint, { "bookId": bookId }, httpOptions)
        .pipe(map(this.extractData));
    } else {
      return of(null);
    }
  }

  placeOrder(transferToCustomerMode: string, transferFromCustomerMode: string): Observable<void> {
    if (this.REAL_BACKEND) {
      const endpoint = LindiLibApiService.BASE_URL + "order/create"
      return this.httpClient
        .post(endpoint, {
          transferToCustomerMode, 
          transferFromCustomerMode
          }, httpOptions)
        .pipe(map(this.extractData));
    } else {
      return of(null);
    }
  }

  getOrders(): Observable<Order[]> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "order/list"
      return this.httpClient
        .get(endpoint, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response.orders;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(orders.items as Array<Order>);
    }
  }

  postOrderComment(orderId: string, message: string): Observable<Order> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "order/post-order-comment"
      return this.httpClient
        .post(endpoint, {
          orderId, 
          message
          }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response.order;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(null);
    }
  }

  addOrderEvent(orderId: string, message: string, newState: string): Observable<Order> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "orderadmin/add-order-event"
      return this.httpClient
        .post(endpoint, {
          orderId, 
          message,
          newState
          }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response.order;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(null);
    }
  }

  getOrdersForAdmin(searchTerm: string): Observable<Order[]> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "orderadmin/list"
      return this.httpClient
        .post(endpoint, { 
          "includeArchive": true,
          "searchTerm": searchTerm}, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response.orders;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(orders.items as Array<Order>);
    }
  }

  getUsersForAdmin(searchTerm: string): Observable<AdminUser[]> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "useradmin/list"
      return this.httpClient
        .post(endpoint, { 
          "searchTerm": searchTerm}, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response.users;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(usersResponse.users as Array<AdminUser>);
    }
  }

  saveAdminUser(user: AdminUser): Observable<void> {
    if (this.REAL_BACKEND) {
      const endpoint = LindiLibApiService.BASE_URL + "useradmin/update"
      return this.httpClient
        .post(endpoint, { "userId": user.id,
              "userAccessLevel": user.newAccessLevel}, 
              httpOptions)
        .pipe(map(this.extractData));
    } else {
      return of(null);
    }
  }  

  getWishlist(): Observable<BookDetails[]> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "wishlist/list"
      return this.httpClient
        .get(endpoint, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response.books;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of((cartResponse as GetCartResponse).books);
    }
  }

  addToWishlist(bookId): Observable<BookDetails> {
    if (this.REAL_BACKEND) {
      this.loadingCounter++;
      const endpoint = LindiLibApiService.BASE_URL + "wishlist/add"
      return this.httpClient
        .post(endpoint, { "bookId": bookId }, httpOptions)
        .pipe(map(this.extractData))
        .pipe(map(response => {
          this.loadingCounter--;
          return response.book;
        }))
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loadingCounter--;
          return throwError(error);
        }));
    } else {
      return of(bookDetails.book);
    }
  }

  removeFromWishlist(bookId): Observable<void> {
    if (this.REAL_BACKEND) {
      const endpoint = LindiLibApiService.BASE_URL + "wishlist/remove"
      return this.httpClient
        .post(endpoint, { "bookId": bookId }, httpOptions)
        .pipe(map(this.extractData));
    } else {
      return of(null);
    }
  }


}
