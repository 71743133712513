import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { NavController, Platform } from '@ionic/angular';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Observable } from 'rxjs';
import { LindiLibApiService } from '../api/lindi-lib-api.service';
import { from } from 'rxjs';
import { concatMap, flatMap, map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userInfo: any;
  private longLivedFacebookAuthToken: string;
  private appAuthToken: string;
  private userAccessLevel: string;

  constructor(
    private platform: Platform,
    private authService: SocialAuthService,
    private lindiLibApi: LindiLibApiService,
    private storage: Storage,
    private navCtrl: NavController) { 
      this.authService.authState.subscribe((user: SocialUser) => {
        if (user != null) {
          console.log("user:", user);
          this.lindiLibApi.renewToken(user.authToken).subscribe(renewResponse => {
            console.log("Renew's got response: ", renewResponse);
            this.longLivedFacebookAuthToken = renewResponse.longLivedFacebookAuthToken;
            this.appAuthToken = renewResponse.appAuthToken;
            this.userAccessLevel = renewResponse.userAccessLevel;
            this.storage.set('lindilib-app-token', renewResponse.appAuthToken);
            this.getUserInfo();
          });
        }
      });
  }

  async signIn(): Promise<void> {
    if (this.platform.is("capacitor")) {
      const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
      const result = await Plugins.FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
      if (result && result.accessToken) {
        this.lindiLibApi.renewToken(result.accessToken.token).subscribe(renewResponse => {
          console.log("Renew's got response: ", renewResponse);
          this.longLivedFacebookAuthToken = renewResponse.longLivedFacebookAuthToken;
          this.appAuthToken = renewResponse.appAuthToken;
          this.userAccessLevel = renewResponse.userAccessLevel;
          this.storage.set('lindilib-app-token', renewResponse.appAuthToken);
          this.getUserInfo();
        });

        this.getUserInfo();
      }
    } else {
      await this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }
  }

  async signInAsGuest(): Promise<void> {
    this.lindiLibApi.renewToken("GUEST_CODE").subscribe(renewResponse => {
      console.log("Renew's got response: ", renewResponse);
      this.longLivedFacebookAuthToken = renewResponse.longLivedFacebookAuthToken;
      this.appAuthToken = renewResponse.appAuthToken;
      this.userAccessLevel = renewResponse.userAccessLevel;
      this.storage.set('lindilib-app-token', renewResponse.appAuthToken);
      this.getUserInfo();
    });
  }

  private async getUserInfo() {
    const response = await fetch(`https://graph.facebook.com/me?fields=id,name,picture&type=large&access_token=${this.longLivedFacebookAuthToken}`);
    const myJson = await response.json();
    console.log("userInfo:", myJson);
    this.userInfo = myJson;
  }

  getAppAuthToken() {
    return this.appAuthToken;
  }

  getFacebookAvatarUrl(): string {
    if (this.userInfo) {
      return this.userInfo.picture.data.url;
    } else {
      return undefined;
    }
  }

  getUserName(): string {
    if (this.userInfo) {
      return this.userInfo.name;
    } else {
      return undefined;
    }
  }

  getFacebookAuthToken(): string {
    return this.longLivedFacebookAuthToken;
  }

  async logout() {
    if (this.platform.is("capacitor")) {
      console.log("trying to log out with native mode")
      await Plugins.FacebookLogin.logout();
    } else {
      console.log("trying to log out with web mode")
      // await this.authService.signOut();
      this.appAuthToken = null;
      this.longLivedFacebookAuthToken = null;
      this.storage.remove('lindilib-app-token');
      this.navCtrl.navigateRoot('/login');
      console.log("logged out with web mode")
    }
  }

  async loginToLindiLib(): Promise<boolean> {
    let appAuthToken = await this.storage.get('lindilib-app-token');
    if (!appAuthToken) {
      console.log('Auth token not found.');
      return false;
    } else {
      let loginResponse = await this.lindiLibApi.login(appAuthToken).toPromise();
      this.appAuthToken = appAuthToken;
      console.log('Lindilib server responded token: ', loginResponse);
      if (loginResponse && loginResponse.longLivedFacebookAuthToken) {
        this.longLivedFacebookAuthToken = loginResponse.longLivedFacebookAuthToken;
        this.userAccessLevel = loginResponse.userAccessLevel;
        await this.getUserInfo();
        return true;
      } else {
        console.log('Lindilib server returned empty auth.');
        this.appAuthToken = undefined;
        return false;
      }
    }
  }

  getUserAccessLevel(): string {
    return this.userAccessLevel;
  }
}
